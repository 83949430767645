<template>
  <MAdvantage class="container" :items="advantages" />
</template>

<script lang="ts" setup>
import { defineComponent } from 'vue'
import { useNuxtApp } from '#app'

import type { Advantage } from '@/components/atoms/Advantage/types'

import MAdvantage from '@/components/molecules/Advantage/MAdvantage.vue'

defineComponent({ name: 'GlobalHomeAdvantages' })

const { $t } = useNuxtApp()

const advantages: Advantage[] = (function () {
  return [
    {
      id: 1,
      src: '/assets/images/advantages/advantages-1.svg',
      title: $t('pages.main.store-chain')
    },
    {
      id: 2,
      src: '/assets/images/advantages/advantages-2.svg',
      title: $t('pages.main.drinks-amount')
    },
    {
      id: 3,
      src: '/assets/images/advantages/advantages-3.svg',
      title: $t('pages.main.reviews-amount')
    },
    {
      id: 4,
      src: '/assets/images/advantages/advantages-4.svg',
      title: $t('pages.main.prices')
    }
  ]
})()
</script>
